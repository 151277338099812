import { Countries, SupportedCountries } from "./Countries";
import toastMessage from "./toastMessage";

/**
 *  Get current redirect Url if available
 * @returns {string}
 */
export const getRedirectUrl = redirectUrl => {
    return redirectUrl ? window.atob(redirectUrl) : "";
}

/**
 * Generate Random string
 * @returns {string}
 */
export const randomString = _ => {
    return (Math.random() + 1).toString(36).substring(2);
}

/* Extract Error Message */
export const getErrorMessage = error => {

    const errData = error?.response?.data?.data;

    if (errData && Array.isArray(errData)) {
        let message = "";
        for (let key in errData) {
            const val = errData[key];
            if (Array.isArray(val))
                message += val[0]
            else if (typeof val == "object")
                for (let keyObj in val) {
                    message += `${val[keyObj]} `;
                }
            else
                message += val;
        }
        return message;
    }

    if (errData && typeof errData === "string") {
        return errData;
    }

    else if (errData && typeof errData === "object") {
        let message = "";
        for (let key in errData) {
            message += `${errData[key]} `;
        }
        return message;
    }

    else if (error?.response?.data?.msg) {
        return error.response.data.msg;
    }

    else if (error?.message) {
        return error.message;
    }

    return `${error}`;
}

/**
 * Copy Text to Clip Board
 * @param {string} text
 */
export const copyToClipboard = text => {
    navigator.clipboard ? navigator.clipboard.writeText(text) : window.Clipboard.setData(text);
    toastMessage("info", "Copied to Clipboard");
}

export const getCurrencyFromPhonenumber = phonenumber => {
    if (!phonenumber)
        return 'NGN';

    return Countries.find(country => phonenumber.startsWith(country.dialCode.toString()))?.currency || 'NGN';
}

/**
 * Checks that there is no value in object
 * @param {Object} obj
 * @returns {Boolean}
 */
export const checkObjectNotExist = obj => {
    if (!obj || obj.constructor !== Object) return true;
    for (let i in obj) return false;
    return true;
}

export const convertUTCToLocalDate = datetime => {
    if (datetime?.toLocaleString().trim().length !== 10) {
        if (datetime?.toLocaleString().trim().length >= 22) {
            return new Date(datetime);
        }
        const curDate = new Date();
        datetime = (new Date(datetime)).getTime() - (curDate.getTimezoneOffset() * 60000);
    }
    return datetime ? new Date(datetime) : null;
}

export const formatDay = (datetime, withToAndYesterday = false) => {
    const event = new Date(convertUTCToLocalDate(datetime));

    if (withToAndYesterday && event.toDateString() === new Date().toDateString()) {
        return "Today"
    }

    if (withToAndYesterday && event.toDateString() === new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toDateString()) {
        return "Yesterday"
    }

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return datetime ? event.toLocaleDateString('en-GB', options) : "";
};

export const formatDateTimeZone = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short', hour12: false };
    const formattedDate =  datetime ? event.toLocaleDateString('en-GB', options) : "";
    return formattedDate.replace(/, (\d):/, ', 0$1:');
};

export const formatDate = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    const formattedDate = datetime ? event.toLocaleDateString('en-GB', options) : "";
    return formattedDate.replace(/, (\d+):/, function(_, hour) {
        let formattedHour = parseInt(hour, 10);
        formattedHour = formattedHour === 0 ? 12 : formattedHour; // Convert 0 to 12
        return `, ${formattedHour.toString().padStart(2, '0')}:`;
    });
};

export const formatWeekDay = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric', };
    return datetime ? event.toLocaleDateString('en-GB', options) : "";
}

/**
 * Use Country currency to obtain currency details
 * @param {string} currency
 * @returns {object}
 */
export const getCurrencyDetails = currency => {
    return ({
        NGN: { symbol: "₦", name: "Naira", currency: currency?.toUpperCase() },
        KES: { symbol: "Ksh", name: "Shilling", currency: currency?.toUpperCase() },

    })[currency?.toUpperCase()] ?? null;
}

export const convertToNumber = val => {
    const value = val?.toLocaleString(undefined, { maximumFractionDigits: 10 })?.replace(/[,]/g, '');
    return parseFloat(value) ? parseFloat(value) : 0;
}

export const formatAmount = (currency, amount, symbol = false) => {
    let amt = parseFloat(amount);
    return symbol ?
        `${getCurrencyDetails(currency)?.symbol ?? currency ?? ""}${amt ? amt?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`
        :
        `${getCurrencyDetails(currency)?.currency ?? currency ?? ""} ${amt ? amt?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
}

// Capitalize first character
export const ucFirst = (s, othersLower = true) => {
    return s && s[0].toUpperCase() + (othersLower ? s.slice(1)?.toLowerCase() : s.slice(1));
}

export const fromCamelCaseToTitleWords = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ')
        .replace(/\b\w/g, c => c.toUpperCase());
}

export const convertToTitleCase = (str) => {
    return str.replace(/_([a-z])/g, function(match, group) {
        return ' ' + group.toUpperCase();
    }).charAt(0).toUpperCase() + str.slice(1);
}

export const getStatusColor = status => {
    status = status.toLowerCase();

    if (status === "failed")
        return "bg-error-3 text-error-2";
    if (status === "completed")
        return "bg-green-3/10 text-green-3";
    if (status === "cancelled" || status === "reversed")
        return "bg-gray-15/22 text-gray-15";

    return "bg-yellow-1/13 text-yellow-2";
}

export const getStatusTableColor = status => {
    status = status.toLowerCase();

    if (status === "failed")
        return "table-circle-red";
    if (status === "completed")
        return "table-circle-green";
    if (status === "cancelled" || status === "reversed")
        return "table-circle-gray";

    return "table-circle-yellow";
}

export const getStatusTextColor = status => {
    status = status.toLowerCase();

    if (status === "failed")
        return "text-error-2";
    if (status === "completed")
        return "text-green-3";
    if (status === "cancelled" || status === "reversed")
        return "text-gray-15";

    return "text-yellow-2";
}

export const getTransactionStatus = data => {
    return data.processing === 1 ? "processing" :
        data.isreversing === 1 ? "reversing" :
            data.reversed === 1 ? "reversed" :
                data.failed === 1 ? "failed" :
                data.failed === 3 ? "cancelled" :
                    data.failed === 0 ? "completed" : "processing";
}

let payment_methods = {
    "transfer_pay": "Bank Transfer",
    "payment_card": "Card",
    "paystack_apple_pay": "Apple Pay",
    "okra": "Vendy Connect"
};
export const getChannelName = transaction => {
    let channel;

    if (payment_methods[transaction.channel]) {
        channel = transaction.meta?.account_display_name
            ? `${payment_methods[transaction.channel]} (${convertToTitleCase(fromCamelCaseToTitleWords(transaction.meta.account_display_name))})`
            : payment_methods[transaction.channel];
        if (transaction.meta?.payer) {
            channel = "Bank Transfer ("+transaction.meta.payer+")";
        }
    } else {
        channel = transaction.meta?.account_display_name
            ? `Vendy Direct (${convertToTitleCase(fromCamelCaseToTitleWords(transaction.meta.account_display_name))})`
            : `-`;
    }

    return channel;
};

export const amountPattern = /^[0-9]+(.[0-9]{1,2})?$/;

/**
 * Use currency to get country code
 * @param {string} currency of the country
 * @returns country code
 */
export const getCountryCode = currency => {
    return (Countries.find((element) => element.currency === currency?.toUpperCase()?.trim()) ||
        SupportedCountries.find((element) => element.currency === "NGN")).code;
}

/**
 * Use currency to get dial code
 * @param {string} currency of the country
 * @returns dial code
 */
export const getPhonenumberDialCode = currency => {
    return (SupportedCountries.find((element) => element.currency === currency?.toUpperCase()?.trim()) ||
        SupportedCountries.find((element) => element.currency === "NGN")).dialCode.toString();
}

export const formatPhonenumber = (phonenumber, currency) => {
    let country = Countries.find((element) => phonenumber.startsWith(element.dialCode));
    if (country)
        return phonenumber;
    let dialCode = getPhonenumberDialCode(currency);
    return dialCode + phonenumber?.replace(/\b0+/g, '');
}

/**
 * extract first and last names from name
 * @param {string} currency of the country
 * @returns dial code
 */
export const getFirstNameAndLastName = name => {
    if (!name)
        return ["", ""];

    let [firstName, lastName] = name.split(' ');
    return lastName?.trim() === "" ? name.split('  ') : [firstName, lastName];
}

export const formatBrowserLocation = browserLocation => {
    let countryCode = browserLocation?.country_code ?? 'NG'
    let locationMeta = SupportedCountries.find((element) => element.code === countryCode?.toUpperCase()?.trim()) || SupportedCountries.find((element) => element.code === "NG");

    let result = { locationMeta: locationMeta, defaultCountry: locationMeta.code }
    if (browserLocation)
        result = { ...result, ...browserLocation }

    return result;

}

export const formatNumberTwoSignificantDigits = (number) => {
    if (!number)
        return '00';
    return number.toLocaleString(undefined, { minimumIntegerDigits: 2 });
}
