import { toast } from "react-toastify";

/**
 * Custom Toast Message
 * 
 * @param {string} type ( info, success, warning, error) 
 * @param {string} message to display
 */
const toastMessage = (type, message) => {

    const options = {
        type,
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
    }

    return toast(<div>{message}</div>, options);
}

export default toastMessage;