import {combineReducers} from "redux";

import HomeSlice from "./home/HomeSlice";
import BalancesSlice from "./balances/BalancesSlice";
import TransactionsSlice from "./transactions/TransactionsSlice";
import CustomersSlice from "./customers/CustomersSlice";
import SettingsSlice from "./settings/SettingsSlice";
import AuthSlice from "./auth/AuthSlice";
import ProfileSlice from "./profile/ProfileSlice";

const appReducer = combineReducers({
  auth: AuthSlice.reducer,
  home: HomeSlice.reducer,
  balances: BalancesSlice.reducer,
  transactions: TransactionsSlice.reducer,
  customers: CustomersSlice.reducer,
  settings: SettingsSlice.reducer,
  profile: ProfileSlice.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/loggedOut') {
    /* Clear All Data on logout */
    state = undefined;
    sessionStorage.setItem("logout", "xxxx"); // To use on re-login with current browser session
  }
  
  return appReducer(state, action)
}


