export const Countries = [
  {
    "name": "American Samoa",
    "dialCode": 1,
    "code": "AS",
    "iso": "ASM",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Anguilla",
    "dialCode": 1,
    "code": "AI",
    "iso": "AIA",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Antigua and Barbuda",
    "dialCode": 1,
    "code": "AG",
    "iso": "ATG",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Argentina",
    "dialCode": 54,
    "code": "AR",
    "iso": "ARG",
    "currency": "ARS",
    "supported": false
  },
  {
    "name": "Aruba",
    "dialCode": 297,
    "code": "AW",
    "iso": "ABW",
    "currency": "AWG",
    "supported": false
  },
  {
    "name": "Australia",
    "dialCode": 61,
    "code": "AU",
    "iso": "AUS",
    "currency": "AUD",
    "supported": false
  },
  {
    "name": "Bahamas",
    "dialCode": 1,
    "code": "BS",
    "iso": "BHS",
    "currency": "BSD",
    "supported": false
  },
  {
    "name": "Bahrain",
    "dialCode": 973,
    "code": "BH",
    "iso": "BHR",
    "currency": "BHD",
    "supported": false
  },
  {
    "name": "Barbados",
    "dialCode": 1,
    "code": "BB",
    "iso": "BRB",
    "currency": "BBD",
    "supported": false
  },
  {
    "name": "Belize",
    "dialCode": 501,
    "code": "BZ",
    "iso": "BLZ",
    "currency": "BZD",
    "supported": false
  },
  {
    "name": "Benin",
    "dialCode": 229,
    "code": "BJ",
    "iso": "BEN",
    "currency": "XOF",
    "supported": false
  },
  {
    "name": "Bermuda",
    "dialCode": 1,
    "code": "BM",
    "iso": "BMU",
    "currency": "BMD",
    "supported": false
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "dialCode": 599,
    "code": "BQ",
    "iso": "BES",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Botswana",
    "dialCode": 267,
    "code": "BW",
    "iso": "BWA",
    "currency": "BWP",
    "supported": false
  },
  {
    "name": "Brazil",
    "dialCode": 55,
    "code": "BR",
    "iso": "BRA",
    "currency": "BRL",
    "supported": false
  },
  {
    "name": "British Indian Ocean Territory",
    "dialCode": 246,
    "code": "IO",
    "iso": "IOT",
    "currency": "GBP",
    "supported": false
  },
  {
    "name": "British Virgin Islands",
    "dialCode": 1,
    "code": "VG",
    "iso": "VGB",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Brunei Darussalam",
    "dialCode": 673,
    "code": "BN",
    "iso": "BRN",
    "currency": "BND",
    "supported": false
  },
  {
    "name": "Burundi",
    "dialCode": 257,
    "code": "BI",
    "iso": "BDI",
    "currency": "BIF",
    "supported": false
  },
  {
    "name": "Cameroon",
    "dialCode": 237,
    "code": "CM",
    "iso": "CMR",
    "currency": "XOF",
    "supported": false
  },
  {
    "name": "Canada",
    "dialCode": 1,
    "code": "CA",
    "iso": "CAN",
    "currency": "CAD",
    "supported": false
  },
  {
    "name": "Cayman Islands",
    "dialCode": 1,
    "code": "KY",
    "iso": "CYM",
    "currency": "KYD",
    "supported": false
  },
  {
    "name": "Chile",
    "dialCode": 56,
    "code": "CL",
    "iso": "CHL",
    "currency": "CLP",
    "supported": false
  },
  {
    "name": "Cook Islands",
    "dialCode": 682,
    "code": "CK",
    "iso": "COK",
    "currency": "NZD",
    "supported": false
  },
  {
    "name": "Costa Rica",
    "dialCode": 506,
    "code": "CR",
    "iso": "CRI",
    "currency": "CRC",
    "supported": false
  },
  {
    "name": "Cote d'Ivoire",
    "dialCode": 225,
    "code": "CI",
    "iso": "CIV",
    "currency": "XOF",
    "supported": false
  },
  {
    "name": "Curacao",
    "dialCode": 599,
    "code": "CW",
    "iso": "CUW",
    "currency": "ANG",
    "supported": false
  },
  {
    "name": "Cyprus",
    "dialCode": 357,
    "code": "CY",
    "iso": "CYP",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Denmark",
    "dialCode": 45,
    "code": "DK",
    "iso": "DNK",
    "currency": "DKK",
    "supported": false
  },
  {
    "name": "Dominica",
    "dialCode": 1,
    "code": "DM",
    "iso": "DMA",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Egypt",
    "dialCode": 20,
    "code": "EG",
    "iso": "EGY",
    "currency": "EGP",
    "supported": false
  },
  {
    "name": "Eritrea",
    "dialCode": 291,
    "code": "ER",
    "iso": "ERI",
    "currency": "ERN",
    "supported": false
  },
  {
    "name": "Ethiopia",
    "dialCode": 251,
    "code": "ET",
    "iso": "ETH",
    "currency": "ETB",
    "supported": false
  },
  {
    "name": "Falkland Islands",
    "dialCode": 500,
    "code": "FK",
    "iso": "FLK",
    "currency": "FKP",
    "supported": false
  },
  {
    "name": "Federated States of Micronesia",
    "dialCode": 691,
    "code": "FM",
    "iso": "FSM",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Fiji",
    "dialCode": 679,
    "code": "FJ",
    "iso": "FJI",
    "currency": "FJD",
    "supported": false
  },
  {
    "name": "France",
    "dialCode": 33,
    "code": "FR",
    "iso": "FRA",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "French Guiana",
    "dialCode": 594,
    "code": "GF",
    "iso": "GUF",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Gambia",
    "dialCode": 220,
    "code": "GM",
    "iso": "GMB",
    "currency": "GMD",
    "supported": false
  },
  {
    "name": "Ghana",
    "dialCode": 233,
    "code": "GH",
    "iso": "GHA",
    "currency": "GHS",
    "supported": false
  },
  {
    "name": "Gibraltar",
    "dialCode": 350,
    "code": "GI",
    "iso": "GIB",
    "currency": "GIP",
    "supported": false
  },
  {
    "name": "Greece",
    "dialCode": 30,
    "code": "GR",
    "iso": "GRC",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Greenland",
    "dialCode": 299,
    "code": "GL",
    "iso": "GRL",
    "currency": "DKK",
    "supported": false
  },
  {
    "name": "Grenada",
    "dialCode": 1,
    "code": "GD",
    "iso": "GRD",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Guadeloupe",
    "dialCode": 590,
    "code": "GP",
    "iso": "GLP",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Guam",
    "dialCode": 1,
    "code": "GU",
    "iso": "GUM",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Guyana",
    "dialCode": 592,
    "code": "GY",
    "iso": "GUY",
    "currency": "GYD",
    "supported": false
  },
  {
    "name": "Hong Kong",
    "dialCode": 852,
    "code": "HK",
    "iso": "HKG",
    "currency": "HKD",
    "supported": false
  },
  {
    "name": "Hungary",
    "dialCode": 36,
    "code": "HU",
    "iso": "HUN",
    "currency": "HUF",
    "supported": false
  },
  {
    "name": "Iceland",
    "dialCode": 354,
    "code": "IS",
    "iso": "ISL",
    "currency": "ISK",
    "supported": false
  },
  {
    "name": "India",
    "dialCode": 91,
    "code": "IN",
    "iso": "IND",
    "currency": "INR",
    "supported": false
  },
  {
    "name": "Indonesia",
    "dialCode": 62,
    "code": "ID",
    "iso": "IDN",
    "currency": "IDR",
    "supported": false
  },
  {
    "name": "Ireland",
    "dialCode": 353,
    "code": "IE",
    "iso": "IRL",
    "currency": "GBP",
    "supported": false
  },
  {
    "name": "Israel",
    "dialCode": 972,
    "code": "IL",
    "iso": "ISR",
    "currency": "ILS",
    "supported": false
  },
  {
    "name": "Jamaica",
    "dialCode": 1,
    "code": "JM",
    "iso": "JAM",
    "currency": "JMD",
    "supported": false
  },
  {
    "name": "Jordan",
    "dialCode": 962,
    "code": "JO",
    "iso": "JOR",
    "currency": "JOD",
    "supported": false
  },
  {
    "name": "Kenya",
    "dialCode": 254,
    "code": "KE",
    "iso": "KEN",
    "currency": "KES",
    "supported": true
  },
  {
    "name": "Kiribati",
    "dialCode": 686,
    "code": "KI",
    "iso": "KIR",
    "currency": "AUD",
    "supported": false
  },
  {
    "name": "Kuwait",
    "dialCode": 965,
    "code": "KW",
    "iso": "KWT",
    "currency": "KWD",
    "supported": false
  },
  {
    "name": "Laos",
    "dialCode": 856,
    "code": "LA",
    "iso": "LAO",
    "currency": "LAK",
    "supported": false
  },
  {
    "name": "Latvia",
    "dialCode": 371,
    "code": "LV",
    "iso": "LVA",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Lebanon",
    "dialCode": 961,
    "code": "LB",
    "iso": "LBN",
    "currency": "LBP",
    "supported": false
  },
  {
    "name": "Lesotho",
    "dialCode": 266,
    "code": "LS",
    "iso": "LSO",
    "currency": "ZAR",
    "supported": false
  },
  {
    "name": "Macao",
    "dialCode": 853,
    "code": "MO",
    "iso": "MAC",
    "currency": "MOP",
    "supported": false
  },
  {
    "name": "Madagascar",
    "dialCode": 261,
    "code": "MG",
    "iso": "MDG",
    "currency": "MGA",
    "supported": false
  },
  {
    "name": "Malawi",
    "dialCode": 265,
    "code": "MW",
    "iso": "MWI",
    "currency": "MWK",
    "supported": false
  },
  {
    "name": "Malaysia",
    "dialCode": 60,
    "code": "MY",
    "iso": "MYS",
    "currency": "MYR",
    "supported": false
  },
  {
    "name": "Maldives",
    "dialCode": 960,
    "code": "MV",
    "iso": "MDV",
    "currency": "MVR",
    "supported": false
  },
  {
    "name": "Mali",
    "dialCode": 223,
    "code": "ML",
    "iso": "MLI",
    "currency": "XOF",
    "supported": false
  },
  {
    "name": "Malta",
    "dialCode": 356,
    "code": "MT",
    "iso": "MLT",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Marshall Islands",
    "dialCode": 692,
    "code": "MH",
    "iso": "MHL",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Martinique",
    "dialCode": 596,
    "code": "MQ",
    "iso": "MTQ",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Mauritius",
    "dialCode": 230,
    "code": "MU",
    "iso": "MUS",
    "currency": "MUR",
    "supported": false
  },
  {
    "name": "Mayotte",
    "dialCode": 262,
    "code": "YT",
    "iso": "MYT",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Monaco",
    "dialCode": 377,
    "code": "MC",
    "iso": "MCO",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Montserrat",
    "dialCode": 1,
    "code": "MS",
    "iso": "MSR",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Namibia",
    "dialCode": 264,
    "code": "NA",
    "iso": "NAM",
    "currency": "NAD",
    "supported": false
  },
  {
    "name": "Nauru",
    "dialCode": 674,
    "code": "NR",
    "iso": "NRU",
    "currency": "AUD",
    "supported": false
  },
  {
    "name": "Nepal",
    "dialCode": 977,
    "code": "NP",
    "iso": "NPL",
    "currency": "NPR",
    "supported": false
  },
  {
    "name": "Netherlands",
    "dialCode": 31,
    "code": "NL",
    "iso": "NLD",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Netherlands Antilles",
    "dialCode": 599,
    "code": "AN",
    "iso": "ANT",
    "currency": "",
    "supported": false
  },
  {
    "name": "New Zealand",
    "dialCode": 64,
    "code": "NZ",
    "iso": "NZL",
    "currency": "NZD",
    "supported": false
  },
  {
    "name": "Nicaragua",
    "dialCode": 505,
    "code": "NI",
    "iso": "NIC",
    "currency": "NIO",
    "supported": false
  },
  {
    "name": "Nigeria",
    "dialCode": 234,
    "code": "NG",
    "iso": "NGA",
    "currency": "NGN",
    "supported": true
  },
  {
    "name": "Niue",
    "dialCode": 683,
    "code": "NU",
    "iso": "NIU",
    "currency": "NZD",
    "supported": false
  },
  {
    "name": "Norfolk Island",
    "dialCode": 672,
    "code": "NF",
    "iso": "NFK",
    "currency": "AUD",
    "supported": false
  },
  {
    "name": "Northern Mariana Islands",
    "dialCode": 1,
    "code": "MP",
    "iso": "MNP",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Oman",
    "dialCode": 968,
    "code": "OM",
    "iso": "OMN",
    "currency": "OMR",
    "supported": false
  },
  {
    "name": "Pakistan",
    "dialCode": 92,
    "code": "PK",
    "iso": "PAK",
    "currency": "PKR",
    "supported": false
  },
  {
    "name": "Palau",
    "dialCode": 680,
    "code": "PW",
    "iso": "PLW",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Palestine",
    "dialCode": 970,
    "code": "PS",
    "iso": "PSE",
    "currency": "EGP",
    "supported": false
  },
  {
    "name": "Panama",
    "dialCode": 507,
    "code": "PA",
    "iso": "PAN",
    "currency": "PAB",
    "supported": false
  },
  {
    "name": "Papua New Guinea",
    "dialCode": 675,
    "code": "PG",
    "iso": "PNG",
    "currency": "PGK",
    "supported": false
  },
  {
    "name": "Peru",
    "dialCode": 51,
    "code": "PE",
    "iso": "PER",
    "currency": "PEN",
    "supported": false
  },
  {
    "name": "Philippines",
    "dialCode": 63,
    "code": "PH",
    "iso": "PHL",
    "currency": "PHP",
    "supported": false
  },
  {
    "name": "Puerto Rico",
    "dialCode": 1,
    "code": "PR",
    "iso": "PRI",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Qatar",
    "dialCode": 974,
    "code": "QA",
    "iso": "QAT",
    "currency": "QAR",
    "supported": false
  },
  {
    "name": "R√©union",
    "dialCode": 262,
    "code": "RE",
    "iso": "REU",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Rwanda",
    "dialCode": 250,
    "code": "RW",
    "iso": "RWA",
    "currency": "RWF",
    "supported": false
  },
  {
    "name": "Saint Barth√©lemy",
    "dialCode": 590,
    "code": "BL",
    "iso": "BLM",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Saint Helena",
    "dialCode": 290,
    "code": "SH",
    "iso": "SHN",
    "currency": "SHP",
    "supported": false
  },
  {
    "name": "Saint Kitts and Nevis",
    "dialCode": 1,
    "code": "KN",
    "iso": "KNA",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Saint Martin",
    "dialCode": 590,
    "code": "MF",
    "iso": "MAF",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "dialCode": 1,
    "code": "VC",
    "iso": "VCT",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Samoa",
    "dialCode": 685,
    "code": "WS",
    "iso": "WSM",
    "currency": "WST",
    "supported": false
  },
  {
    "name": "Sao Tome and Principe",
    "dialCode": 239,
    "code": "ST",
    "iso": "STP",
    "currency": "STN",
    "supported": false
  },
  {
    "name": "Senegal",
    "dialCode": 221,
    "code": "SN",
    "iso": "SEN",
    "currency": "XOF",
    "supported": false
  },
  {
    "name": "Seychelles",
    "dialCode": 248,
    "code": "SC",
    "iso": "SYC",
    "currency": "SCR",
    "supported": false
  },
  {
    "name": "Sierra Leone",
    "dialCode": 232,
    "code": "SL",
    "iso": "SLE",
    "currency": "SLL",
    "supported": false
  },
  {
    "name": "Singapore",
    "dialCode": 65,
    "code": "SG",
    "iso": "SGP",
    "currency": "SGD",
    "supported": false
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "dialCode": 1,
    "code": "SX",
    "iso": "SXM",
    "currency": "ANG",
    "supported": false
  },
  {
    "name": "Solomon Islands",
    "dialCode": 677,
    "code": "SB",
    "iso": "SLB",
    "currency": "SBD",
    "supported": false
  },
  {
    "name": "Somalia",
    "dialCode": 252,
    "code": "SO",
    "iso": "SOM",
    "currency": "SOS",
    "supported": false
  },
  {
    "name": "South Africa",
    "dialCode": 27,
    "code": "ZA",
    "iso": "ZAF",
    "currency": "ZAR",
    "supported": false
  },
  {
    "name": "South Korea",
    "dialCode": 82,
    "code": "KR",
    "iso": "KOR",
    "currency": "KRW",
    "supported": false
  },
  {
    "name": "Sri Lanka",
    "dialCode": 94,
    "code": "LK",
    "iso": "LKA",
    "currency": "LKR",
    "supported": false
  },
  {
    "name": "Saint Lucia",
    "dialCode": 1,
    "code": "LC",
    "iso": "LCA",
    "currency": "XCD",
    "supported": false
  },
  {
    "name": "Suriname",
    "dialCode": 597,
    "code": "SR",
    "iso": "SUR",
    "currency": "SRD",
    "supported": false
  },
  {
    "name": "Swaziland",
    "dialCode": 268,
    "code": "SZ",
    "iso": "SWZ",
    "currency": "SZL",
    "supported": false
  },
  {
    "name": "Switzerland",
    "dialCode": 41,
    "code": "CH",
    "iso": "CHE",
    "currency": "CHF",
    "supported": false
  },
  {
    "name": "Tanzania",
    "dialCode": 255,
    "code": "TZ",
    "iso": "TZA",
    "currency": "TZS",
    "supported": false
  },
  {
    "name": "Thailand",
    "dialCode": 66,
    "code": "TH",
    "iso": "THA",
    "currency": "THB",
    "supported": false
  },
  {
    "name": "Timor-Leste",
    "dialCode": 670,
    "code": "TL",
    "iso": "TLS",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Tokelau",
    "dialCode": 690,
    "code": "TK",
    "iso": "TKL",
    "currency": "NZD",
    "supported": false
  },
  {
    "name": "Tonga",
    "dialCode": 676,
    "code": "TO",
    "iso": "TON",
    "currency": "TOP",
    "supported": false
  },
  {
    "name": "Trinidad and Tobago",
    "dialCode": 1,
    "code": "TT",
    "iso": "TTO",
    "currency": "TTD",
    "supported": false
  },
  {
    "name": "Turks and Caicos Islands",
    "dialCode": 1,
    "code": "TC",
    "iso": "TCA",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Tuvalu",
    "dialCode": 688,
    "code": "TV",
    "iso": "TUV",
    "currency": "AUD",
    "supported": false
  },
  {
    "name": "Uganda",
    "dialCode": 256,
    "code": "UG",
    "iso": "UGA",
    "currency": "UGX",
    "supported": true
  },
  {
    "name": "United Arab Emirates",
    "dialCode": 971,
    "code": "AE",
    "iso": "ARE",
    "currency": "AED",
    "supported": false
  },
  {
    "name": "United Kingdom",
    "dialCode": 44,
    "code": "GB",
    "iso": "GBR",
    "currency": "GBP",
    "supported": false
  },
  {
    "name": "United States",
    "dialCode": 1,
    "code": "US",
    "iso": "USA",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "U.S. Virgin Islands",
    "dialCode": 1,
    "code": "VI",
    "iso": "VIR",
    "currency": "USD",
    "supported": false
  },
  {
    "name": "Vanuatu",
    "dialCode": 678,
    "code": "VU",
    "iso": "VUT",
    "currency": "VUV",
    "supported": false
  },
  {
    "name": "Vatican City",
    "dialCode": 39,
    "code": "VA",
    "iso": "VAT",
    "currency": "EUR",
    "supported": false
  },
  {
    "name": "Vietnam",
    "dialCode": 84,
    "code": "VN",
    "iso": "VNM",
    "currency": "VND",
    "supported": false
  },
  {
    "name": "Zambia",
    "dialCode": 260,
    "code": "ZM",
    "iso": "ZMB",
    "currency": "ZMW",
    "supported": false
  }
];

export const SupportedCountries = Countries.filter(item => item.supported);