/**
 * Intercept all axios request by injecting necessary headers
 * @param {Redux} store redux store 
 * @param {Axios} axios axios instance
 */
const setupAxiosInterceptors = (store, axios) => {

  // Request Interceptor
  axios.interceptors.request.use(
    config => {
      const { auth: { token } } = store.getState();

      if (token) config.headers.Authorization = `Bearer ${token}`;

      config.url = process.env.REACT_APP_API_HOST + config.url;
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    err => Promise.reject(err)
  );

}

export default setupAxiosInterceptors;