import { Suspense } from "react"
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor } from "./states/store";
import Routes from "./routes";
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";

const App = ({ store, basename }) => {
  return <>
    {/* Provide Redux store  */}
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div></div>}>
        <Suspense fallback={<div></div>}>
          <BrowserRouter basename={basename} >
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
              <Routes />
            </IntercomProvider>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
    <ToastContainer />
  </>
}

export default App;
