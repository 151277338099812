import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { getBrowserLocation } from "../states/auth/AuthHandler";

// Lazy load Auth and Non Auth Section of the site
const NotAuthenticatedRoutes = React.lazy(() => import("./NotAuthenticatedRoutes"));
const AuthenticatedRoutes = React.lazy(() => import('./AuthenticatedRoutes'));

const Routes = _ => {

    const dispatch = useDispatch();

    const { boot, shutdown } = useIntercom();

    const authenticated = useSelector(({ auth }) => auth?.authenticated);
    const [email, browserLocation, id, name, userHash] = useSelector(({ profile, auth }) => [profile.profile?.email ?? auth.email, auth.browserLocation, profile.profile?.id, profile.profile?.name, profile.profile?.hash ? JSON.parse(profile.profile?.hash)?.w : null], shallowEqual);

    //get browser location
    useEffect(_ => {
        if (!browserLocation)
            dispatch(getBrowserLocation());
    }, [browserLocation, dispatch]);

    // Intercom Initialiser
    useEffect(_ => {
        const intercomUser = {};
        if (id) intercomUser.userId = id;
        if (email && authenticated) intercomUser.email = email;
        if (name) intercomUser.name = name;
        if (userHash) intercomUser.userHash = userHash;
        boot(intercomUser);
        return shutdown;
    }, [boot, shutdown, email, id, name, userHash, authenticated])

    // Facebook Initialiser
    useEffect(() => {
        if(authenticated) {
            // <!-- Load the Facebook JavaScript SDK -->
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            } (document, 'script', 'facebook-jssdk'));

            window.fbAsyncInit = () => window.FB.init({
                appId: '364355332215327',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v19.0'
            });
        }
    }, [authenticated]);

    return authenticated ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />
}

export default Routes;
