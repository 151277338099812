import { createSlice } from "@reduxjs/toolkit";

const initialHomeState = {
  isLoading: false,
  notification: null,
  notificationOptions: null,
  transactionSummarySales: null,
  transactionSummaryTrans: null,
  transactionSummaryCommission: null,
  channels: null
};

export default createSlice({
  name: "home",
  initialState: initialHomeState,
  reducers: {

    // Home Response Notification
    homeNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Home Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Home Reducers
     */

    // updateNotifications
    notificationsUpdated: (state, action) => {
      state.notificationOptions = action.payload.allowedEvents;
    },

    setTransactionSummary: (state, action) => {
      state.isLoading = false;
      state.transactionSummarySales = action.payload.value;
      state.transactionSummaryTrans = action.payload.volume;
      state.transactionSummaryCommission = action.payload.commission;
    },

    setChannels: (state, action) => {
      state.isLoading = false;
      state.channels = action.payload.data;
      state.notification = action.payload.notification;
    },

    channelsReset: (state) => {
      state.isLoading = false;
      state.channels = null;
    }

  }
});