import { createSlice } from "@reduxjs/toolkit";

const initialSettingsState = {
  isLoading: false,
  notification: null,
  payouts: null,
  banks: null,
  webhooks: null,
  bankAccountDetails: null
};

export default createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {

    // Settings Response Notification
    settingsNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Settings Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Settings Reducers
     */

    // updateNotifications
    setPayouts: (state, action) => {
      state.isLoading = false;
      state.payouts = action.payload;
    },

    setWebhooks: (state, action) => {
      state.isLoading = false;
      state.webhooks = action.payload ?? [];
    },

    setBanks: (state, action) => {
      state.isLoading = false;
      state.banks = action.payload;
    },

    bankAccountDetailsValidated: (state, action) => {
      state.isLoading = false;
      state.bankAccountDetails = action.payload;
    },

    bankAccountDetailsReset: (state) => {
      state.isLoading = false;
      state.bankAccountDetails = null;
    },
  }
});