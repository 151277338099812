import { createSlice } from "@reduxjs/toolkit";

const initialCustomersState = {
  isLoading: false,
  notification: null,
  notificationOptions: null
};

export default createSlice({
  name: "customers",
  initialState: initialCustomersState,
  reducers: {

    // Customers Response Notification
    customersNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Customers Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Customers Reducers
     */

    // updateNotifications
    notificationsUpdated: (state, action) => {
      state.notificationOptions = action.payload.allowedEvents;
    }

  }
});