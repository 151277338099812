import { createSlice } from "@reduxjs/toolkit";

const initialBalancesState = {
  isLoading: false,
  notification: null,
  salesWallet: null,
  salesWallets: null,
  merchantWallet: null,
  commissionWallets:null,
  businessPayouts: null,
  salesWalletPayouts: null,
  merchantWalletPayouts: null
};

export default createSlice({
  name: "balances",
  initialState: initialBalancesState,
  reducers: {

    // Balances Response Notification
    balancesNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Balances Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    /*****
     *  Balances Reducers
     */

    setWallets: (state, action) => {
      state.isLoading = false;
      let wallets = action.payload ?? [];
      state.salesWallet = wallets.find(item => item.type?.toLowerCase() === "balance");
      state.salesWallets = wallets.filter(item => item.type?.toLowerCase() === "balance");
      state.merchantWallet = wallets.find(item => item.type?.toLowerCase() === "commission");
      state.commissionWallets = wallets.filter(item => item.type?.toLowerCase() === "commission");
    },

    setDefaultWallet: (state, action) => {
      state.salesWallet = state.salesWallets.find(item => item.id === action.payload);
    },

    setDefaultCommissionWallet: (state, action) => {
      state.merchantWallet = state.commissionWallets.find(item => item.id === action.payload);
    },

    setBusinessPayouts: (state, action) => {
      state.isLoading = false;
      state.businessPayouts = action.payload;
    },

    setSalesWalletPayouts: (state, action) => {
      state.isLoading = false;
      state.salesWalletPayouts = action.payload;
    },

    setMerchantWalletPayouts: (state, action) => {
      state.isLoading = false;
      state.merchantWalletPayouts = action.payload;
    }

  }
});