import { createSlice } from "@reduxjs/toolkit";
import { formatBrowserLocation } from "../../common/util/Helpers";

const initialAuthState = {
  isLoading: false,
  notification: null,
  authenticated: false,
  token: null,
  email: null,
  userData: null,
  browserLocation: null
};

export default createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    // Auth Response Notification
    authNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Auth Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Auth Reducers
     */

    authenticated: (state, action) => {
      state.isLoading = false;
      state.token = action.payload.token;
      state.authenticated = true;
    },

    loggedIn: (state, action) => {
      state.isLoading = false;
      state.token = action.payload.data.token;
      state.userData = action.payload.data;
      state.notification = action.payload.notification;
    },

    setAuthenticated: (state, _) => {
      state.isLoading = false;
      state.authenticated = true;
    },

    passwordResetInitiated: (state, action) => {
      state.isLoading = false;
      state.email = action.payload.email;
      state.notification = action.payload.notification;
    },

    loggedOut: state => {
      state.isLoading = false;
    },

    setBrowserLocation: (state, action) => {
      state.isLoading = false;
      state.browserLocation = formatBrowserLocation(action.payload ?? {});
    },

  }

});