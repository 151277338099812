import { createSlice } from "@reduxjs/toolkit";

const initialTransactionsState = {
  isLoading: false,
  notification: null,
  allTransactions: null,
  searchTransactions: [],
  transactionsListEnd: false,
  searchTransactionsListEnd: false,
  transactionFeeDetails: null,
  transactionCSVSent: false
};

export default createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {

    // Transactions Response Notification
    transactionsNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // Transactions Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Transactions Reducers
     */

    setTransactions: (state, action) => {
      state.isLoading = false;
      state.transactionsListEnd = action.payload.length < 20;
      state.allTransactions = action.payload;
    },
    
    setTransactionCSVSent: (state, action) =>{
        state.isLoading = false;
        state.transactionCSVSent = action.payload;
    },

    setSearchTransactions: (state, action) => {
      state.isLoading = false;
      state.searchTransactionsListEnd = action.payload.length < 20;
      state.searchTransactions = action.payload ?? [];
    },

    setMoreTransactions: (state, action) => {
      state.isLoading = false;
      state.transactionsListEnd = action.payload.length < 20;
      state.allTransactions = [...state.allTransactions, ...action.payload];
    },

    setMoreSearchTransactions: (state, action) => {
      state.isLoading = false;
      state.searchTransactionsListEnd = action.payload.length < 20;
      state.searchTransactions = [...state.searchTransactions, ...action.payload];
    },

    setTransactionFeeDetails: (state, action) => {
      state.isLoading = false;
      state.transactionFeeDetails = action.payload;
    },

    resetTransactionFeeDetails: (state, _) => {
      state.isLoading = false;
      state.transactionFeeDetails = null;
    }

  }
});