import { createSlice } from "@reduxjs/toolkit";

const initialProfileState = {
    isLoading: false,
    notification: null,
    openLeftNav: false,
    profile: null,
    businessId: null,
    business: null,
    businesses: null,
    kyc: null,
    footer: true
};

export default createSlice({
    name: "profile",
    initialState: initialProfileState,
    reducers: {
        // Profile Response Notification
        profileNotification: (state, action) => {
            state.isLoading = false;
            state.notification = action.payload.notification;
        },

        // Profile Loading
        isLoading: (state, _) => {
            state.isLoading = true;
            state.notification = null
        },
        /*****
         *  Profile Reducers
         */

        toggleLeftNav: (state, _) => {
            state.openLeftNav = !state.openLeftNav;
        },
        setProfile: (state, action) => {
            state.isLoading = false;
            state.profile = { ...action.payload };
        },
        setBusinesses: (state, action) => {
            state.isLoading = false;
            let business = action.payload[0] ?? {};
            state.businesses = action.payload ?? [];
            state.business = { ...business };
            state.businessId = business.id;
        },
        changeActiveBusiness: (state, action) => {
            console.log(action.payload);
            let business = state.businesses.find((e) => {
                return e.id === action.payload;
            });
            state.business = business;
            state.businessId = business.id;
        },
        businessCreated: _ => { },
        setKyc: (state, action) => {
            state.isLoading = false;
            state.kyc = action.payload;
        },
        removeFooter: (state) => {
            state.footer = false;
        }
    }
});