import AuthSlice from "./AuthSlice";
import * as requestSender from "./AuthRequestSender";
import { getErrorMessage } from "../../common/util/Helpers";


const { actions } = AuthSlice;

/* Api Request Error Handler */
const errorHandler = dispatch => error => {
    if (error?.message && error.message === "Cancel Request") return;
    dispatch(actions.authNotification({ notification: { type: "error", message: getErrorMessage(error) } }));
}

export const clearAuthNotification = _ => dispatch => dispatch(actions.authNotification({ notification: null }));

export const loginAndAuthenticate = (password, email, phonenumber = null) => dispatch => {
    dispatch(actions.isLoading());
    (email ? requestSender.loginReq(email, password) : requestSender.phoneLoginReq(phonenumber, password))
        .then(({ data: { data } }) => dispatch(actions.authenticated({ ...data })))
        .catch(err => {
            if (err?.response?.status === 401 && err?.response?.data?.code === 202) {
                return dispatch(actions.passwordResetInitiated({ email, notification: { action: "moveToActivate" } }));
            }
            errorHandler(dispatch)(err);
        });
};

export const login = (password, email, phonenumber = null, handleError = true) => dispatch => {
    dispatch(actions.isLoading());
    (email ? requestSender.loginReq(email, password) : requestSender.phoneLoginReq(phonenumber, password))
        .then(({ data: { data } }) => dispatch(actions.loggedIn({ data, notification: { action: "loggedIn" } })))
        .catch(err => {
            if (err?.message && err.message === "Cancel Request") return;
            if (err?.response?.status === 401 && err?.response?.data?.code === 202)
                dispatch(actions.passwordResetInitiated({ email, notification: { action: "moveToActivate" } }));
            else if (handleError)
                dispatch(actions.authNotification({ notification: { type: "error", message: getErrorMessage(err) } }));
            else
                dispatch(actions.authNotification({ notification: { action: "loginFailed" } }));
        });
};

export const authenticate = _ => dispatch => {
    dispatch(actions.isLoading());
    dispatch(actions.setAuthenticated());
}

export const signup = (signupData) => dispatch => {
    dispatch(actions.isLoading());
    const name = signupData?.firstName + " " + signupData?.lastName;
    requestSender.signupReq({ ...signupData, name })
        .then(_ => dispatch(actions.passwordResetInitiated({ email: signupData?.email, notification: { action: "signupSuccessful" } })))
        .catch(errorHandler(dispatch));
};

export const forgotPassword = (email) => dispatch => {
    dispatch(actions.isLoading());
    requestSender.forgotPasswordReq(email)
        .then(_ => dispatch(actions.passwordResetInitiated({ email, notification: { action: "otpSent" } })))
        .catch(errorHandler(dispatch));
};

export const verifyEmail = (email, otp) => dispatch => {
    dispatch(actions.isLoading());
    requestSender.verifyEmailReq(email, otp)
        .then(_ => dispatch(actions.authNotification({ notification: { action: "emailVerified" } })))
        .catch(errorHandler(dispatch));
};

export const signupPhone = (signupData) => dispatch => {
    dispatch(actions.isLoading());
    const name = signupData?.firstName.trim() + " " + signupData?.lastName.trim();
    requestSender.signupPhoneReq({ ...signupData, name })
        .then(_ => dispatch(actions.authNotification({ notification: { action: "phonenumberVerified" } })))
        .catch(err => {
            if (err?.response?.status === 401 && err?.response?.data?.code === 205) {
                return dispatch(actions.authNotification({ notification: { action: "accountExists" } }));
            }
            errorHandler(dispatch)(err);
        });
};

export const resendOtp = (email) => dispatch => {
    dispatch(actions.isLoading());
    requestSender.resendOtpReq(email)
        .then(({ data }) => {
            if (data.msg.includes("User already activated"))
                dispatch(actions.authNotification({ notification: { action: "emailVerified" } }));
            else
                dispatch(actions.authNotification({ notification: { type: "success", message: "Otp Successfully Sent", action: "otpSent" } }));
        })
        .catch(errorHandler(dispatch));
};

export const resetPassword = (resetPasswordData) => dispatch => {
    dispatch(actions.isLoading());
    requestSender.resetPasswordReq(resetPasswordData)
        .then(_ => dispatch(actions.authNotification({ notification: { action: "passwordReset" } })))
        .catch(errorHandler(dispatch));
};

export const logout = _ => dispatch => {
    dispatch(actions.isLoading());
    requestSender.logoutReq()
        .then(_ => dispatch(actions.loggedOut()))
        .catch(_ => dispatch(actions.loggedOut()));
};

export const getBrowserLocation = _ => dispatch => {
    dispatch(actions.isLoading());
    requestSender.getBrowserLocationReq()
        .then(r => r.json().then(data => {
            if (r.status === 200)
                dispatch(actions.setBrowserLocation(data));
            else
                dispatch(actions.setBrowserLocation({}));
        }))
        .catch(err => {
            console.log(err);
            dispatch(actions.setBrowserLocation({}));
        });
};