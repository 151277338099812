import axios from "axios";

export const verifyEmailReq = (email, otp) => {
    return axios.post('/user/activate', { email, otp });
}

export const resendOtpReq = email => {
    return axios.post('/user/resend-code', { email });
}
//firstName, lastName, name, email, phone, password
export const signupReq = signupData => {
    return axios.post('/user/create', { ...signupData });
}

//idToken, firstName, lastName, name, email, phone, password, country
export const signupPhoneReq = signupData => {
    return axios.post('/user/firebase/create', { ...signupData });
}

export const loginReq = (email, password) => {
    return axios.post("/user/login", { email, password });
}

export const phoneLoginReq = (phonenumber, password) => {
    return axios.post("/user/login", { phonenumber, password });
}

export const forgotPasswordReq = (email) => {
    return axios.post("/user/reset-password", { email });
}

//otp, email, password
export const resetPasswordReq = (resetPasswordData) => {
    return axios.post("/user/confirm-reset", { ...resetPasswordData });
}

export const logoutReq = _ => {
    return axios.post("/user/logout", {});
}

export const getBrowserLocationReq = _ => {
    return fetch('https://ipapi.co/json', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });
}