import React from 'react';
import ReactDOM from 'react-dom';
import store from './app/states/store';
import axios from "axios";
import App from './app/index';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import setupAxiosInterceptors from './app/states/setupAxiosInterceptors';

setupAxiosInterceptors(store, axios);

ReactDOM.render(
  <React.StrictMode>
      <App store={store} />
  </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
